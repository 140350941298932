/*styles for the best seller page!*/
#bestsellers {
  margin-top: 100px;
}

.bestsellers {
  display: flex;
}

.bestsellers__section {
  margin-bottom: 150px;
}

.bestsellers__section:last-child {
  margin-bottom: 0;
}

.bestsellers .card__container {
  text-decoration: none;
  color: #343434;
  width: 170px;
  min-width: 170px;
  flex: 1;
  margin-right: 25px;
}

.bestsellers .card {
  box-shadow: none;
  cursor: pointer;
}

#bestsellers .card .media-content p {
  margin-bottom: 0.5em;
}

#bestsellers .header {
  margin: 0;
  margin-bottom: 80px;
  font-size: 32px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: .5px;
  color: #343434;
  display: flex;
  align-items: center;
}

#bestsellers .header .is-left {
  font-size: 14px;
  color: #343434;
  margin-left: 20px;
}

.card__copy {
  margin-top: 30px;
}

.card__tag {
  margin-bottom: 10px;
}

.card__image {
  height: 200px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

.card__image:hover {
  transform: scale(1.15);
  transition: transform 300ms ease-in-out;
}

.card__title {
  font-size: 16px;
  letter-spacing: .5px;
  font-weight: 700;
  margin-top: 20px;
}

.card__author {
  font-size: 14px;
  margin-top: 2px;
  line-height: 22px;
}

.card__author span {
  font-weight: 600;
}
