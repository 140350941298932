@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /*max-width: 1366px;*/
  margin: 0 auto;
  padding: 0;
  padding-bottom: 150px;
}

.book-tag {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .5px;
  padding: 6px 12px;
  background: rgb(240,240,240);
  border-radius: 4px;
  text-transform: uppercase;
  width: fit-content;
}

.nav-link {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #4a4a4a;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.nav-link .icon {
  margin-right: 8px;
}

.nav-link:hover {
  opacity: .8;
  transition: opacity 300ms ease-in-out;
}

.nav-link .logo {
  height: 30px;
  width: auto;
  margin-right: 10px;
}

.home-nav-link {
  margin-top: 50px;
  width: fit-content;
}

.home-nav-link .nav-link {
  font-weight: 700;
  letter-spacing: 3px;
  font-size: 16px;
}

.back-nav-link {
  margin-bottom: 50px;
}

.cta {
  background: #e6e6e6;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  border: none;
  height: 48px;
  font-size: 14px;
  min-width: 110px;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
  transition: background 100ms ease-out;
}

.cta:hover {
  background: #dedede;
  transition: background 300ms ease-in;
}

.cancel-cta {
  background: #ffd9d9;
  color: #e60000;
}

.cancel-cta:hover {
  background: #ffcecc;
}
