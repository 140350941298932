.search-bar {
  position: fixed;
  top: 0;
  height: 80px;
  z-index: 100;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  background: white;
  overflow: hidden;
  padding: 0 30px;
}

.search-bar__input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-bar__input .icon {
  color: #e8e8e8;
}

.search-bar input {
  flex: 1;
  font-weight: 400;
  border: none;
  box-shadow: none;
  padding: 0 20px;
  padding-right: 0;
  outline: 0;
  font-size: 20px;
  letter-spacing: .5px;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
}

.search-bar input::placeholder {
  color: #e8e8e8;
}
