@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /*max-width: 1366px;*/
  margin: 0 auto;
  padding: 0;
  padding-bottom: 150px;
}

.book-tag {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .5px;
  padding: 6px 12px;
  background: rgb(240,240,240);
  border-radius: 4px;
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.nav-link {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #4a4a4a;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.nav-link .icon {
  margin-right: 8px;
}

.nav-link:hover {
  opacity: .8;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}

.nav-link .logo {
  height: 30px;
  width: auto;
  margin-right: 10px;
}

.home-nav-link {
  margin-top: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.home-nav-link .nav-link {
  font-weight: 700;
  letter-spacing: 3px;
  font-size: 16px;
}

.back-nav-link {
  margin-bottom: 50px;
}

.cta {
  background: #e6e6e6;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  border: none;
  height: 48px;
  font-size: 14px;
  min-width: 110px;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
  -webkit-transition: background 100ms ease-out;
  transition: background 100ms ease-out;
}

.cta:hover {
  background: #dedede;
  -webkit-transition: background 300ms ease-in;
  transition: background 300ms ease-in;
}

.cancel-cta {
  background: #ffd9d9;
  color: #e60000;
}

.cancel-cta:hover {
  background: #ffcecc;
}

@charset "UTF-8";

@media only screen and (max-width: 768px) {
  #app {
    margin: 20px;
  }
}

.data-loader {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 50%;
  -webkit-animation: loader 2s infinite ease;
          animation: loader 2s infinite ease;
  margin: 0 50%;
}

.data-loader__inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #E2E2E2;
  -webkit-animation: loader-inner 2s infinite ease-in;
          animation: loader-inner 2s infinite ease-in;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

/* Book */
#book {
  margin-top: calc(80px + 5em);
  display: flex;
  flex-direction: column;
}

.book__row {
  display: flex;
}

/* Modal */
.modal {
  z-index: 200;
}

#book .modal-card {
  text-align: center;
  padding: 50px;
}

#book .modal-card-body {
  text-align: center;
  padding: 50px 0;
  border-radius: 4px;
  box-shadow: 0 12px 32px rgba(0,0,0,.2);
}

#book .modal-card-header {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

#book .modal-card-header > div {
  display: inline;
}

#book .modal-card-details {
  font-size: 16px;
}

#book .modal-card-details p {
  margin-bottom: 20px;
}

#book .modal-card-buttons .cta {
  margin-top: 40px;
  margin-right: 30px;
}

.modal-card-buttons .cta:last-child {
  margin-right: 0;
}

.modal-card-body .store-logo {
  height: 60px;
  width: 120px;
  background-size: contain;
  background-position: left center;
  margin-left: 10px;
  background-repeat: no-repeat;
}

#book .modal-card-buttons .cancel {
  background-color: #ffd9d9;
  color: #ed322d;
  border: 0;
}

#book .modal-card-buttons .cancel:hover {
  opacity: 0.8;
}

#book .modal-card-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#book .modal-card-details {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.book {
  display: flex;
}

.book__cover {
  background-size: cover;
  background-repeat: no-repeat;
  height: 280px;
  min-width: 185px;
  width: 185px;
  box-shadow: 0 5px 20px rgba(0,0,0,.1);
}

.book__details {
  margin-left: 80px;
}

.book__copy .book__isbn {
  color: #a0a0a0;
  font-size: 14px;
}

.book__copy .book__title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 1.5px;
  margin: 8px 0 5px 0;
}

.book__copy .book__author {
  font-size: 20px;
  font-weight: 300;
}

.book__copy .book__author b {
  font-weight: 400;
}

.book__copy .book__description {
  line-height: 32px;
  color: #141414;
  font-weight: 300;
  margin: 50px 0 40px 0;
}

.stores {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.header {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.header-2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 50px;
}

.stores .store {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  height: 100px;
  align-items: center;
}

.stores .store:last-child {
  border-bottom: none;
}

.store .store__logo-wrapper,
.store .store__price,
.store .store__buy-btn {
  flex: 1 1;
}

.store .store__logo {
  height: 60px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.store .store__price {
  text-align: center;
}

.store .store__buy-btn {
  text-align: right;
}

/**
 *  Recommendations
 */
.recommendations-wrapper {
  margin-top: 150px;
}

.recommendations {
  display: flex;
  overflow-x: scroll;
}

.recommendations::-webkit-scrollbar {
  display: none;
}

.recommendation__book {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
}

.recommendation__book__cover {
  width: 150px;
  min-height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}

.recommendation__book__title {
  font-size: 16px;
  letter-spacing: .5px;
  font-weight: 700;
  text-transform: uppercase;
  color: #4a4a4a;;
}

.recommendation__book__author {
  font-size: 14px;
  margin-top: 2px;
  line-height: 22px;
  color: #4a4a4a;;
}

.recommendation__book__author b {
  font-weight: 600;
}

.recommendation__book__author span {
  font-weight: 700;
}


/**
 * Modal animation
 */
.alert-enter {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

/*styles for the search page!*/
#search {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#search .debug {
  font-size: 0.5em;
  margin-top: 10px;
}

.search-input .icon {
  font-family: "Font Awesome 5 Free";
  margin-right: 10px;
  color: #e8e8e8;
  font-weight: 300;
}

#search input:focus {
  outline: 0;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.search-instruction {
  color: #a0a0a0;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
  -webkit-transition: border-color 100ms linear;
  transition: border-color 100ms linear;
  z-index: 10;
}

.search-input--focused {
  border-bottom-color: #b5b5b5;
  -webkit-transition: border-color 300ms linear;
  transition: border-color 300ms linear;
}

.search-input input {
  flex: 1 1;
  font-weight: 600;
  border: none;
  box-shadow: none;
  padding: 0 10px;
  height: 80px;
  outline: 0;
  font-size: 32px;
  letter-spacing: 1.5px;
  font-family: 'Open Sans', sans-serif;
}

.search-input input::-webkit-input-placeholder {
  color: #e8e8e8;
}

.search-input input::-moz-placeholder {
  color: #e8e8e8;
}

.search-input input:-ms-input-placeholder {
  color: #e8e8e8;
}

.search-input input::-ms-input-placeholder {
  color: #e8e8e8;
}

.search-input input::placeholder {
  color: #e8e8e8;
}

#search .search__error {
  color: #af0000;
}

#search .search__error, #search .search__tooltip {
  font-size: 12px;
  margin-top: 10px;
  opacity: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: opacity 300ms linear, -webkit-transform 300ms ease-in-out;
  transition: opacity 300ms linear, -webkit-transform 300ms ease-in-out;
  transition: opacity 300ms linear, transform 300ms ease-in-out;
  transition: opacity 300ms linear, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

#search .search__error--display, #search .search__tooltip--display {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

#search .search__error span {
  font-weight: 600;
}

/*styles for the best seller page!*/
#bestsellers {
  margin-top: 100px;
}

.bestsellers {
  display: flex;
}

.bestsellers__section {
  margin-bottom: 150px;
}

.bestsellers__section:last-child {
  margin-bottom: 0;
}

.bestsellers .card__container {
  text-decoration: none;
  color: #343434;
  width: 170px;
  min-width: 170px;
  flex: 1 1;
  margin-right: 25px;
}

.bestsellers .card {
  box-shadow: none;
  cursor: pointer;
}

#bestsellers .card .media-content p {
  margin-bottom: 0.5em;
}

#bestsellers .header {
  margin: 0;
  margin-bottom: 80px;
  font-size: 32px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: .5px;
  color: #343434;
  display: flex;
  align-items: center;
}

#bestsellers .header .is-left {
  font-size: 14px;
  color: #343434;
  margin-left: 20px;
}

.card__copy {
  margin-top: 30px;
}

.card__tag {
  margin-bottom: 10px;
}

.card__image {
  height: 200px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

.card__image:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  -webkit-transition: -webkit-transform 300ms ease-in-out;
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.card__title {
  font-size: 16px;
  letter-spacing: .5px;
  font-weight: 700;
  margin-top: 20px;
}

.card__author {
  font-size: 14px;
  margin-top: 2px;
  line-height: 22px;
}

.card__author span {
  font-weight: 600;
}

/*styles for the search page!*/
.search-results {
  margin-top: 100px;
}

.search-results__number {
  margin-bottom: 40px;
  font-size: 16px;
  letter-spacing: .5px;
  color: #727272;
}

.search-results__number b {
  color: black;
}

.search-result {
  display: flex;
  cursor: pointer;
}

.search-results__result {
  margin-bottom: 30px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 30px;
}

.search-results__result:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.search-result__image {
  height: 160px;
  width: 110px;
  background-size: cover;
}

.search-result__copy {
  flex: 1 1;
  margin-left: 25px;
  margin-top: 10px;
}

.search-result__title {
  font-size: 16px;
  letter-spacing: .5px;
  font-weight: 700;
}

.search-result__author {
  font-size: 14px;
  margin-top: 2px;
  line-height: 22px;
}

.search-result__author span {
  font-weight: 700;
}

.search-result__tag {
  margin-top: 20px;
}

.book-tag--bestseller {
  background: rgb(252, 238, 206);
  color: rgb(209, 151, 52);
}

.search-bar {
  position: fixed;
  top: 0;
  height: 80px;
  z-index: 100;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  background: white;
  overflow: hidden;
  padding: 0 30px;
}

.search-bar__input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-bar__input .icon {
  color: #e8e8e8;
}

.search-bar input {
  flex: 1 1;
  font-weight: 400;
  border: none;
  box-shadow: none;
  padding: 0 20px;
  padding-right: 0;
  outline: 0;
  font-size: 20px;
  letter-spacing: .5px;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
}

.search-bar input::-webkit-input-placeholder {
  color: #e8e8e8;
}

.search-bar input::-moz-placeholder {
  color: #e8e8e8;
}

.search-bar input:-ms-input-placeholder {
  color: #e8e8e8;
}

.search-bar input::-ms-input-placeholder {
  color: #e8e8e8;
}

.search-bar input::placeholder {
  color: #e8e8e8;
}

