/* Book */
#book {
  margin-top: calc(80px + 5em);
  display: flex;
  flex-direction: column;
}

.book__row {
  display: flex;
}

/* Modal */
.modal {
  z-index: 200;
}

#book .modal-card {
  text-align: center;
  padding: 50px;
}

#book .modal-card-body {
  text-align: center;
  padding: 50px 0;
  border-radius: 4px;
  box-shadow: 0 12px 32px rgba(0,0,0,.2);
}

#book .modal-card-header {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

#book .modal-card-header > div {
  display: inline;
}

#book .modal-card-details {
  font-size: 16px;
}

#book .modal-card-details p {
  margin-bottom: 20px;
}

#book .modal-card-buttons .cta {
  margin-top: 40px;
  margin-right: 30px;
}

.modal-card-buttons .cta:last-child {
  margin-right: 0;
}

.modal-card-body .store-logo {
  height: 60px;
  width: 120px;
  background-size: contain;
  background-position: left center;
  margin-left: 10px;
  background-repeat: no-repeat;
}

#book .modal-card-buttons .cancel {
  background-color: #ffd9d9;
  color: #ed322d;
  border: 0;
}

#book .modal-card-buttons .cancel:hover {
  opacity: 0.8;
}

#book .modal-card-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#book .modal-card-details {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.book {
  display: flex;
}

.book__cover {
  background-size: cover;
  background-repeat: no-repeat;
  height: 280px;
  min-width: 185px;
  width: 185px;
  box-shadow: 0 5px 20px rgba(0,0,0,.1);
}

.book__details {
  margin-left: 80px;
}

.book__copy .book__isbn {
  color: #a0a0a0;
  font-size: 14px;
}

.book__copy .book__title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 1.5px;
  margin: 8px 0 5px 0;
}

.book__copy .book__author {
  font-size: 20px;
  font-weight: 300;
}

.book__copy .book__author b {
  font-weight: 400;
}

.book__copy .book__description {
  line-height: 32px;
  color: #141414;
  font-weight: 300;
  margin: 50px 0 40px 0;
}

.stores {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.header {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.header-2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 50px;
}

.stores .store {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  height: 100px;
  align-items: center;
}

.stores .store:last-child {
  border-bottom: none;
}

.store .store__logo-wrapper,
.store .store__price,
.store .store__buy-btn {
  flex: 1;
}

.store .store__logo {
  height: 60px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.store .store__price {
  text-align: center;
}

.store .store__buy-btn {
  text-align: right;
}

/**
 *  Recommendations
 */
.recommendations-wrapper {
  margin-top: 150px;
}

.recommendations {
  display: flex;
  overflow-x: scroll;
}

.recommendations::-webkit-scrollbar {
  display: none;
}

.recommendation__book {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
}

.recommendation__book__cover {
  width: 150px;
  min-height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}

.recommendation__book__title {
  font-size: 16px;
  letter-spacing: .5px;
  font-weight: 700;
  text-transform: uppercase;
  color: #4a4a4a;;
}

.recommendation__book__author {
  font-size: 14px;
  margin-top: 2px;
  line-height: 22px;
  color: #4a4a4a;;
}

.recommendation__book__author b {
  font-weight: 600;
}

.recommendation__book__author span {
  font-weight: 700;
}


/**
 * Modal animation
 */
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
