/*styles for the search page!*/
.search-results {
  margin-top: 100px;
}

.search-results__number {
  margin-bottom: 40px;
  font-size: 16px;
  letter-spacing: .5px;
  color: #727272;
}

.search-results__number b {
  color: black;
}

.search-result {
  display: flex;
  cursor: pointer;
}

.search-results__result {
  margin-bottom: 30px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 30px;
}

.search-results__result:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.search-result__image {
  height: 160px;
  width: 110px;
  background-size: cover;
}

.search-result__copy {
  flex: 1;
  margin-left: 25px;
  margin-top: 10px;
}

.search-result__title {
  font-size: 16px;
  letter-spacing: .5px;
  font-weight: 700;
}

.search-result__author {
  font-size: 14px;
  margin-top: 2px;
  line-height: 22px;
}

.search-result__author span {
  font-weight: 700;
}

.search-result__tag {
  margin-top: 20px;
}

.book-tag--bestseller {
  background: rgb(252, 238, 206);
  color: rgb(209, 151, 52);
}
