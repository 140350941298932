.data-loader {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 50%;
  animation: loader 2s infinite ease;
  margin: 0 50%;
}

.data-loader__inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #E2E2E2;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
