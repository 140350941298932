/*styles for the search page!*/
#search {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#search .debug {
  font-size: 0.5em;
  margin-top: 10px;
}

.search-input .icon {
  font-family: "Font Awesome 5 Free";
  margin-right: 10px;
  color: #e8e8e8;
  font-weight: 300;
}

#search input:focus {
  outline: 0;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.search-instruction {
  color: #a0a0a0;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
  transition: border-color 100ms linear;
  z-index: 10;
}

.search-input--focused {
  border-bottom-color: #b5b5b5;
  transition: border-color 300ms linear;
}

.search-input input {
  flex: 1;
  font-weight: 600;
  border: none;
  box-shadow: none;
  padding: 0 10px;
  height: 80px;
  outline: 0;
  font-size: 32px;
  letter-spacing: 1.5px;
  font-family: 'Open Sans', sans-serif;
}

.search-input input::placeholder {
  color: #e8e8e8;
}

#search .search__error {
  color: #af0000;
}

#search .search__error, #search .search__tooltip {
  font-size: 12px;
  margin-top: 10px;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 300ms linear, transform 300ms ease-in-out;
}

#search .search__error--display, #search .search__tooltip--display {
  opacity: 1;
  transform: translateY(0);
}

#search .search__error span {
  font-weight: 600;
}
